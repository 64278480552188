
import { defineComponent, onMounted, onUnmounted, ref, watch, ComputedRef, computed } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useChannels, useDeleteChannel } from '@/composables/api';
import {
  ChannelIdOption,
  DEFAULT_PAGE_SIZE,
  DELETED_SUCCESSFULLY_TEXT,
  ResponseError,
  getChannelAgents,
  MAX_PER_PAGE
} from '@/services/api';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { getThousandSeparator } from '@/utils/render';
import { formatLocalTime } from '@/utils/format-time';
import { useKeyword, usePage } from '@/composables';
import { useRoute, useRouter } from 'vue-router';
import { ChannelAgent, Promotion } from '@/interfaces';

export default defineComponent({
  components: {
    permissionUnits,
    SearchBox,
    Filter
  },
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const router = useRouter();
    const route = useRoute();
    const channelAgentId = ref<string>();

    const {
      data,
      isLoading,
      isFetching,
      refetch
    } = useChannels({ page, pageSize, keyword, channelAgentId });
    const { isLoading: isDeleting, mutate } = useDeleteChannel();

    const currentChannelId = ref<ChannelIdOption['channelId']>();

    const deleteChannel = ({ channelId }: ChannelIdOption) => {
      currentChannelId.value = channelId;

      ElMessageBox.confirm('是否確認要刪除？', '警告', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { channelId },
            {
              onSuccess() {
                refetch.value();

                ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
              },
              onError(error: ResponseError) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const getPromotion = (promotion: Promotion) => {
      return Promotion[promotion];
    };

    /**
     * channelAgents
     */
    const channelAgents = ref<ChannelAgent[]>([]);
    const channelAgentsOptions = ref([]);
    const fetchChannelAgents = async() => {
      channelAgents.value = (await getChannelAgents({ query: { pageSize: MAX_PER_PAGE } })).data;
    };
    watch(() => channelAgents.value, () => {
      channelAgentsOptions.value = channelAgents.value.map(({ username, id }) => ({ label: `${username}`, value: `${id}` }));
    });
    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '代理帳號',
          placeholder: '請選擇',
          options: channelAgentsOptions.value
        }
      ];
    });
    const handleFilterChange = (event: FilterEvent) => {
      channelAgentId.value = event[0];
    };

    onMounted(() => {
      fetchChannelAgents();
    });

    onUnmounted(() => {
      route.query.page = String(page.value);
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      currentChannelId,
      isDeleting,
      keyword,
      setPageQuery,
      deleteChannel,
      formatLocalTime,
      getThousandSeparator,
      filterOptions,
      handleFilterChange,
      getPromotion
    };
  }
});
