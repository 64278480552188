
import { computed, ComputedRef, watch, defineComponent, onMounted, ref, onUnmounted } from 'vue';
import { useApproveChannelAgentWithdrawal, useChannelAgentWithdrawals, useChannelAgentWithdrawalStatistics, useTransferChannelAgentWithdrawalFailed, useTransferChannelAgentWithdrawalSuccess } from '@/composables/api';
import { ChannelAgentWithdrawalIdOption, exportChannelAgentWithdrawals, ResponseError, SortOrder, DEFAULT_PAGE_SIZE, OrderSortKey } from '@/services/api';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { ChannelAgentWithdrawalStatus } from '@/interfaces';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import PanelGroup from './components/PanelGroup.vue';
import dayjs from 'dayjs';
import { getStatusTagType, getThousandSeparator } from '@/utils/render';
import download from 'downloadjs';
import { ElMessage } from 'element-plus';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { useRoute, useRouter } from 'vue-router';
import { useKeyword, usePage, useSort } from '@/composables';

const translate = {
  AUDITING: '審核中',
  SUCCESS: '支付成功',
  FAILED: '支付失敗'
};

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    PanelGroup
  },
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const startDate = ref();
    const endDate = ref();
    const dateRange = ref();
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);
    const currentIndex = ref();
    const status = ref();

    const { sortKey, sortOrder } = useSort<OrderSortKey>();
    const { data, isLoading, isFetching, refetch } = useChannelAgentWithdrawals({
      startDate,
      endDate,
      page,
      pageSize,
      sortKey,
      sort: sortOrder,
      keyword,
      status
    });
    const { isLoading: isApproving, mutate: approve } = useApproveChannelAgentWithdrawal();
    const { isLoading: isSuccessUpdating, mutate: updateSuccessStatus } = useTransferChannelAgentWithdrawalSuccess();
    const { isLoading: isFailedUpdating, mutate: updateFailedStatus } = useTransferChannelAgentWithdrawalFailed();
    const { data: statisticsData } = useChannelAgentWithdrawalStatistics({ startDate, endDate, keyword, status });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '狀態',
          placeholder: '請選擇',
          options: Object
            .entries(ChannelAgentWithdrawalStatus)
            .map(([label, value]) => ({ label: translate[label], value }))
        }
      ];
    });

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      order === 'ascending'
        ? sortOrder.value = SortOrder.ASCENDING
        : sortOrder.value = SortOrder.DESCENDING;

      if (!prop) {
        sortOrder.value = null;
      }
    };

    const handleDateRangeChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }

      startDate.value = formatISOString(dateRange.value[0]);
      endDate.value = formatISOString(dateRange.value[1]);
    };

    const handleFilterChange = (event: FilterEvent) => {
      status.value = event[0];
    };

    const router = useRouter();
    const route = useRoute();

    const handleExcelExport = async() => {
      const data = await exportChannelAgentWithdrawals({
        query: {
          startDate: startDate.value,
          endDate: endDate.value,
          sortKey: sortKey.value,
          sort: sortOrder.value,
          keyword: keyword.value,
          status: status.value
        }
      });

      const currentDate = dayjs().format('YYYYMMDD');
      // 下載匯出檔案
      download(data, `渠道流量列表_${currentDate}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    };

    const approveWithdrawal = ({ channelAgentWithdrawalId }: ChannelAgentWithdrawalIdOption, index: Number) => {
      currentIndex.value = index;

      approve(
        { channelAgentWithdrawalId },
        {
          onSuccess() {
            refetch.value();

            ElMessage.success('通過批核');
          },
          onError(error: ResponseError) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const updateStatusToSuccess = ({ channelAgentWithdrawalId }: ChannelAgentWithdrawalIdOption, index: Number) => {
      currentIndex.value = index;

      updateSuccessStatus(
        { channelAgentWithdrawalId },
        {
          onSuccess() {
            refetch.value();

            ElMessage.success('狀態更新成功');
          },
          onError(error: ResponseError) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const updateStatusToFailed = ({ channelAgentWithdrawalId }: ChannelAgentWithdrawalIdOption, index: Number) => {
      currentIndex.value = index;

      updateFailedStatus(
        { channelAgentWithdrawalId },
        {
          onSuccess() {
            refetch.value();

            ElMessage.success('狀態更新成功');
          },
          onError(error: ResponseError) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    watch([startDate, endDate], ([startDate, endDate]) => {
      router.push({ query: { ...route.query, startDate, endDate } });
    });

    watch(
      () => route.query,
      (query) => {
        startDate.value = query.startDate as string;
        endDate.value = query.endDate as string;

        if (startDate.value && endDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    onUnmounted(() => {
      route.query.page = String(page.value);
    });

    return {
      page,
      data,
      dateRange,
      isLoading,
      isFetching,
      filterOptions,
      defaultTime,
      sortOrder,
      statisticsData,
      keyword,
      getThousandSeparator,
      handleFilterChange,
      formatLocalTime,
      handleDateRangeChange,
      getStatusTagType,
      approveWithdrawal,
      currentIndex,
      ChannelAgentWithdrawalStatus,
      isApproving,
      updateStatusToSuccess,
      updateStatusToFailed,
      isSuccessUpdating,
      isFailedUpdating,
      sortChange,
      handleExcelExport,
      setPageQuery
    };
  }
});
